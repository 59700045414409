import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.usepantry.com';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  console.log('Token:', token); // Add this line
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export const login = (username, password) => api.post('/login/', { username, password });
export const register = (username, email, password) => api.post('/register/', { username, email, password });
export const initialSetup = (ingredients) => api.post('/initial-setup/', { ingredients });
export const getIngredients = () => api.get('/ingredients/');
export const updateIngredient = (id, data) => api.patch(`/ingredients/${id}/`, data);
export const createIngredient = (data) => api.post('/ingredients/', data);
export const deleteIngredient = (id) => api.delete(`/ingredients/${id}/`);
export const getRecipes = () => api.get('/recipes/');
export const getMealPlans = () => api.get('/meal-plans/');
export const getGroceryList = () => api.get('/grocery-lists/');
export const toggleGroceryItem = (itemId) => api.post('/grocery-lists/toggle_item/', { item_id: itemId });
export const chat = (message) => api.post('/chat/', { message });
export const addToGroceryList = (items) => api.post('/grocery-lists/add_items/', { items });
export const getRecipe = (id) => api.get(`/recipes/${id}/`);

export const deleteRecipe = (id) => api.delete(`/recipes/${id}/`);
export const deleteMealPlan = (id) => api.delete(`/meal-plans/${id}/`);
export const deleteGroceryItem = (itemId) => api.delete('/grocery-lists/delete_item/', { data: { item_id: itemId } });

export const updateGroceryList = async (recipeId) => {
  try {
    const response = await api.post('/update-grocery-list/', { recipe_id: recipeId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addToMealPlan = (recipeId) => api.post('/meal-plans/', { recipe: recipeId });

export const checkOffGroceryItem = (itemId) => api.post('/grocery-lists/check-off-item/', { item_id: itemId });

export const extractRecipeFromURL = (url) => api.post('/extract-recipe/', { url });

export const getUserProfile = () => api.get('/user-profiles/me/');
export const updateUserProfile = (data) => api.put('/user-profiles/update_me/', data);

export default api;