import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Link, Paper } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { login } from '../../services/api';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await login(username, password);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
      navigate('/chat');
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <StyledContainer className={StyledContainer.root} maxWidth="xs">
      <StyledPaper className={StyledPaper.paper}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <StyledButton type="submit" variant="contained" color="primary" fullWidth className={StyledButton.button}>
            Login
          </StyledButton>
        </form>
        <Typography align="center" style={{ marginTop: '1rem' }}>
          Don't have an account? <Link component={RouterLink} to="/register">Sign up</Link>
        </Typography>
      </StyledPaper>
    </StyledContainer>
  );
}

export default Login;