import React, { useState, useEffect } from 'react';
import {
  Box, Container, Heading, VStack, HStack, Text, Input, Button,
  Card, CardBody, CardFooter, useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  getRecipes,
  deleteRecipe,
  addToMealPlan,
  extractRecipeFromURL,
} from '../../services/api';

function Recipes() {
  const [recipes, setRecipes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [recipeURL, setRecipeURL] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const response = await getRecipes();
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
      toast({
        title: 'Error fetching recipes',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteRecipe = async (id) => {
    try {
      await deleteRecipe(id);
      fetchRecipes();
      toast({
        title: 'Recipe deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting recipe:', error);
      toast({
        title: 'Error deleting recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddToMealPlan = async (id) => {
    try {
      await addToMealPlan(id);
      toast({
        title: 'Added to meal plan',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding to meal plan:', error);
      toast({
        title: 'Error adding to meal plan',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleExtractRecipe = async () => {
    setLoading(true);
    try {
      await extractRecipeFromURL(recipeURL);
      setRecipeURL('');
      fetchRecipes();
      toast({
        title: 'Recipe imported successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error extracting recipe:', error);
      toast({
        title: 'Error importing recipe',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const filteredRecipes = recipes.filter((recipe) => {
    const query = searchQuery.toLowerCase();
    const nameMatch = recipe.name.toLowerCase().includes(query);
    const instructionsMatch = recipe.instructions.toLowerCase().includes(query);
    const ingredientsMatch = recipe.ingredients.some((ingredient) =>
      ingredient.ingredient_name.toLowerCase().includes(query)
    );
    return nameMatch || instructionsMatch || ingredientsMatch;
  });

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Recipes</Heading>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="Recipe URL"
          value={recipeURL}
          onChange={(e) => setRecipeURL(e.target.value)}
        />
        <Button
          colorScheme="teal"
          onClick={handleExtractRecipe}
          isLoading={loading}
        >
          Import Recipe
        </Button>
        <Input
          placeholder="Search Recipes"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {filteredRecipes.map((recipe) => (
          <Card key={recipe.id}>
            <CardBody>
              <Heading size="md">{recipe.name}</Heading>
              <Text>{recipe.instructions}</Text>
            </CardBody>
            <CardFooter>
              <HStack spacing={2}>
                <Button onClick={() => navigate(`/recipes/${recipe.id}`)}>
                  View Details
                </Button>
                <Button onClick={() => handleDeleteRecipe(recipe.id)}>
                  Delete
                </Button>
                <Button onClick={() => handleAddToMealPlan(recipe.id)}>
                  Add to Meal Plan
                </Button>
              </HStack>
            </CardFooter>
          </Card>
        ))}
        <Button colorScheme="teal" onClick={fetchRecipes}>
          Refresh Recipes
        </Button>
      </VStack>
    </Container>
  );
}

export default Recipes;