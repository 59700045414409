import React from 'react';
import {
  Box,
  Flex,
  Drawer,
  DrawerContent,
  useDisclosure,
  IconButton,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiHome,
  FiUser,
  FiMessageSquare,
  FiList,
  FiBookOpen,
  FiCalendar,
  FiShoppingCart,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

const LinkItems = [
  { name: 'Chat', icon: FiMessageSquare, path: '/chat' },
  { name: 'Inventory', icon: FiHome, path: '/inventory' },
  { name: 'Recipes', icon: FiBookOpen, path: '/recipes' },
  { name: 'Meal Planner', icon: FiCalendar, path: '/meal-planner' },
  { name: 'Grocery List', icon: FiShoppingCart, path: '/grocery-list' },
  { name: 'Profile', icon: FiUser, path: '/profile' },
];

function Layout({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh">
      <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* Mobile Nav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => (
  <Box
    bg="gray.100"
    borderRight="1px"
    borderRightColor="gray.200"
    w={{ base: 'full', md: 60 }}
    pos="fixed"
    h="full"
    {...rest}
  >
    <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
      <Text fontSize="2xl" fontWeight="bold">
        Pantry
      </Text>
      <IconButton display={{ base: 'flex', md: 'none' }} onClick={onClose} icon={<FiMenu />} />
    </Flex>
    {LinkItems.map((link) => (
      <NavItem key={link.name} icon={link.icon} path={link.path}>
        {link.name}
      </NavItem>
    ))}
  </Box>
);

const NavItem = ({ icon, children, path, ...rest }) => (
  <Link to={path} style={{ textDecoration: 'none' }}>
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'teal.400',
        color: 'white',
      }}
      {...rest}
    >
      {icon && (
        <Box mr="4" fontSize="16">
          {React.createElement(icon)}
        </Box>
      )}
      {children}
    </Flex>
  </Link>
);

const MobileNav = ({ onOpen, ...rest }) => (
  <Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 24 }}
    height="20"
    alignItems="center"
    bg="white"
    borderBottomWidth="1px"
    borderBottomColor="gray.200"
    justifyContent="flex-start"
    {...rest}
  >
    <IconButton onClick={onOpen} icon={<FiMenu />} variant="outline" />
    <Text fontSize="2xl" ml="8" fontWeight="bold">
      Pantry
    </Text>
  </Flex>
);

export default Layout;