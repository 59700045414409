import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  List,
  ListItem,
  useToast
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { getMealPlans, deleteMealPlan, updateGroceryList, getGroceryList } from '../../services/api';

function MealPlanner() {
  const [mealPlans, setMealPlans] = useState([]);
  const [groceryList, setGroceryList] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchMealPlans();
  }, []);

  const fetchMealPlans = async () => {
    try {
      const response = await getMealPlans();
      setMealPlans(response.data);
    } catch (error) {
      console.error('Error fetching meal plans:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch meal plans. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMealPlan = async (id) => {
    try {
      const response = await deleteMealPlan(id);
      if (response.status === 204 || (response.data && response.data.status === 'success')) {
        fetchMealPlans();
        toast({
          title: 'Success',
          description: 'Meal plan deleted successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to delete meal plan');
      }
    } catch (error) {
      console.error('Error deleting meal plan:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete meal plan. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddToGroceryList = async (recipeId) => {
    try {
      const result = await updateGroceryList(recipeId);
      if (result.status === 'success') {
        toast({
          title: 'Success',
          description: 'Grocery list updated successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchGroceryList();
      } else {
        throw new Error('Failed to update grocery list');
      }
    } catch (error) {
      console.error('Error updating grocery list:', error);
      toast({
        title: 'Error',
        description: 'Failed to update grocery list. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const fetchGroceryList = async () => {
    try {
      const response = await getGroceryList();
      setGroceryList(response.data);
    } catch (error) {
      console.error('Error fetching grocery list:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch updated grocery list.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Meal Planner</Heading>
      <VStack spacing={4} align="stretch">
        <List spacing={3}>
          {mealPlans.map((mealPlan) => (
            <ListItem key={mealPlan.id} p={2} bg="gray.50" borderRadius="md">
              <HStack justify="space-between">
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold">{mealPlan.recipe.name}</Text>
                  <Text fontSize="sm" color="gray.600">
                    {new Date(mealPlan.date).toLocaleDateString()}
                  </Text>
                </VStack>
                <HStack>
                  <Button size="sm" onClick={() => handleAddToGroceryList(mealPlan.recipe.id)}>
                    Add to Grocery List
                  </Button>
                  <IconButton
                    size="sm"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteMealPlan(mealPlan.id)}
                    aria-label="Delete meal plan"
                  />
                </HStack>
              </HStack>
            </ListItem>
          ))}
        </List>
        <Button colorScheme="teal" onClick={fetchMealPlans}>
          Refresh Meal Plans
        </Button>
      </VStack>
    </Container>
  );
}

export default MealPlanner;