import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  VStack,
  HStack,
  Text,
  Input,
  Button,
  List,
  ListItem,
  UnorderedList,
  ListItem as ChakraListItem,
  useToast
} from '@chakra-ui/react';
import { chat } from '../../services/api';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const response = await chat(input);
      console.log('Chat Response:', response.data);
      const assistantMessage = {
        role: 'assistant',
        content: response.data.response,
        recipe_name: response.data.recipe_name,
        recipe_id: response.data.recipe_id,
        ingredients: response.data.ingredients,
        instructions: response.data.instructions,
        missing_ingredients: response.data.missing_ingredients,
      };
      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description: 'Failed to send message. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={4} align="stretch" height="calc(100vh - 200px)">
        <Box flex={1} overflowY="auto" bg="gray.50" p={4} borderRadius="md">
          <List spacing={3}>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
              >
                <Box
                  bg={message.role === 'user' ? 'blue.100' : 'green.100'}
                  p={3}
                  borderRadius="md"
                  maxW="70%"
                >
                  {message.role === 'user' ? (
                    <Text>{message.content}</Text>
                  ) : (
                    <VStack align="start" spacing={2}>
                      <Text>{message.content}</Text>

                      {message.recipe_name && message.recipe_id && (
                        <Text>
                          <Text as="span" fontWeight="bold">
                            Recipe Name:
                          </Text>{' '}
                          <Link to={`/recipes/${message.recipe_id}`} style={{ color: 'blue' }}>
                            {message.recipe_name}
                          </Link>
                        </Text>
                      )}

                      {message.ingredients && message.ingredients.length > 0 && (
                        <>
                          <Text fontWeight="bold">Ingredients:</Text>
                          <UnorderedList pl={5}>
                            {message.ingredients.map((ing, idx) => (
                              <ChakraListItem key={idx}>
                                {ing.quantity} {ing.unit} {ing.name}
                              </ChakraListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}

                      {message.instructions && (
                        <>
                          <Text fontWeight="bold">Instructions:</Text>
                          <Text>{message.instructions}</Text>
                        </>
                      )}
                    </VStack>
                  )}
                </Box>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>
        <HStack as="form" onSubmit={(e) => { e.preventDefault(); handleSendMessage(); }}>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
          />
          <Button colorScheme="teal" onClick={handleSendMessage} isLoading={loading}>
            Send
          </Button>
        </HStack>
      </VStack>
    </Container>
  );
}

export default Chat;