import React from 'react';
import {
  Box,
  Button,
  Text,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function LandingPage() {
  return (
    <Container maxW="sm" centerContent py={8}>
      <Box
        bg="white"
        p={8}
        rounded="md"
        boxShadow="lg"
        textAlign="center"
      >
        <Heading mb={4}>Welcome to Pantry</Heading>
        <Text mb={6}>
          Manage your ingredients, plan meals, and get recipe suggestions.
        </Text>
        <Flex justify="center" gap={4}>
          <Button as={RouterLink} to="/login" colorScheme="teal">
            Login
          </Button>
          <Button as={RouterLink} to="/register" variant="outline" colorScheme="teal">
            Sign Up
          </Button>
        </Flex>
      </Box>
    </Container>
  );
}

export default LandingPage;