import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem, Spinner } from '@chakra-ui/react';
import { getRecipe } from '../../services/api';

function RecipeDetail() {
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        setLoading(true);
        console.log('Fetching recipe with id:', id);
        const response = await getRecipe(id);
        console.log('Recipe data:', response.data);
        setRecipe(response.data);
      } catch (error) {
        console.error('Error fetching recipe:', error);
        setError('Failed to fetch recipe. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchRecipe();
  }, [id]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!recipe) {
    return <Text>No recipe found.</Text>;
  }

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="xl">{recipe.name}</Heading>
        <Box>
          <Heading as="h2" size="lg" mb={2}>Ingredients:</Heading>
          <UnorderedList>
            {recipe.ingredients.map((ingredient, index) => (
              <ListItem key={index}>
                {`${ingredient.quantity}${ingredient.unit ? ' ' + ingredient.unit : ''} ${ingredient.ingredient_name}`.trim()}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Box>
          <Heading as="h2" size="lg" mb={2}>Instructions:</Heading>
          <Text>{recipe.instructions}</Text>
        </Box>
      </VStack>
    </Container>
  );
}

export default RecipeDetail;