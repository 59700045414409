import React from 'react';
import { Box, Container, Grid, Heading, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function Dashboard() {
  return (
    <Container maxW="container.xl" py={8}>
      <Heading as="h1" mb={6}>Dashboard</Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <DashboardItem title="Inventory" to="/inventory" />
        <DashboardItem title="Recipes" to="/recipes" />
        <DashboardItem title="Meal Planner" to="/meal-planner" />
        <DashboardItem title="Grocery List" to="/grocery-list" />
        <DashboardItem title="Chat" to="/chat" />
      </Grid>
    </Container>
  );
}

function DashboardItem({ title, to }) {
  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" bg="gray.50">
      <ChakraLink as={RouterLink} to={to} fontSize="xl" fontWeight="semibold">
        {title}
      </ChakraLink>
    </Box>
  );
}

export default Dashboard;