import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast
} from '@chakra-ui/react';
import { getUserProfile, updateUserProfile } from '../../services/api';

function Profile() {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    dietary_restrictions: '',
    cooking_skill: '',
    allergies: '',
    health_goal: '',
  });
  const toast = useToast();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch user profile. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUserProfile(profile);
      toast({
        title: 'Success',
        description: 'Profile updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to update profile. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.md" py={8}>
      <Heading mb={6}>User Profile</Heading>
      <Box as="form" onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input name="username" value={profile.username} isReadOnly />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input name="email" value={profile.email} isReadOnly />
          </FormControl>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input name="first_name" value={profile.first_name} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input name="last_name" value={profile.last_name} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Dietary Restrictions</FormLabel>
            <Input
              name="dietary_restrictions"
              value={profile.dietary_restrictions}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Cooking Skill</FormLabel>
            <Select name="cooking_skill" value={profile.cooking_skill} onChange={handleInputChange}>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Allergies</FormLabel>
            <Input
              name="allergies"
              value={profile.allergies}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Health Goal</FormLabel>
            <Select name="health_goal" value={profile.health_goal} onChange={handleInputChange}>
              <option value="lose_weight">Lose Weight</option>
              <option value="build_muscle">Build Muscle</option>
              <option value="cut_carbs">Cut Carbs</option>
            </Select>
          </FormControl>
          <Button type="submit" colorScheme="teal">
            Update Profile
          </Button>
        </VStack>
      </Box>
    </Container>
  );
}

export default Profile;


