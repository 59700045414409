import React, { useState, useEffect } from 'react';
import {
  Box, Container, Heading, VStack, HStack, Text, Input, Button, IconButton,
  List, ListItem, useToast
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { getIngredients, updateIngredient, createIngredient, deleteIngredient } from '../../services/api';

function Inventory() {
  const [ingredients, setIngredients] = useState([]);
  const [newIngredient, setNewIngredient] = useState({ name: '', quantity: '', unit: '' });
  const toast = useToast();

  useEffect(() => {
    fetchIngredients();
  }, []);

  const fetchIngredients = async () => {
    try {
      const response = await getIngredients();
      setIngredients(response.data);
    } catch (error) {
      console.error('Error fetching ingredients:', error);
      toast({
        title: 'Error fetching ingredients',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateQuantity = async (id, newQuantity) => {
    try {
      await updateIngredient(id, { quantity: newQuantity });
      fetchIngredients();
    } catch (error) {
      console.error('Error updating ingredient:', error);
      toast({
        title: 'Error updating ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddIngredient = async () => {
    try {
      await createIngredient(newIngredient);
      setNewIngredient({ name: '', quantity: '', unit: '' });
      fetchIngredients();
      toast({
        title: 'Ingredient added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding ingredient:', error);
      toast({
        title: 'Error adding ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteIngredient = async (id) => {
    try {
      await deleteIngredient(id);
      fetchIngredients();
      toast({
        title: 'Ingredient deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting ingredient:', error);
      toast({
        title: 'Error deleting ingredient',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Inventory</Heading>
      <Box>
        <List spacing={3}>
          {ingredients.map((ingredient) => (
            <ListItem key={ingredient.id}>
              <HStack>
                <Text flex="1">{ingredient.name}</Text>
                <Input
                  type="number"
                  value={ingredient.quantity}
                  onChange={(e) => handleUpdateQuantity(ingredient.id, e.target.value)}
                  width="100px"
                />
                <Text>{ingredient.unit}</Text>
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteIngredient(ingredient.id)}
                  aria-label="Delete ingredient"
                />
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box mt={8}>
        <Heading size="md" mb={4}>Add New Ingredient</Heading>
        <VStack spacing={4}>
          <Input
            placeholder="Name"
            value={newIngredient.name}
            onChange={(e) => setNewIngredient({ ...newIngredient, name: e.target.value })}
          />
          <Input
            placeholder="Quantity"
            type="number"
            value={newIngredient.quantity}
            onChange={(e) => setNewIngredient({ ...newIngredient, quantity: e.target.value })}
          />
          <Input
            placeholder="Unit"
            value={newIngredient.unit}
            onChange={(e) => setNewIngredient({ ...newIngredient, unit: e.target.value })}
          />
          <Button colorScheme="teal" onClick={handleAddIngredient}>
            Add Ingredient
          </Button>
        </VStack>
      </Box>
    </Container>
  );
}

export default Inventory;